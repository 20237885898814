import styled from "styled-components";

const getPadding = (sTheme: string) => {
  if (sTheme === "nomad") {
    return "0";
  }

  if (sTheme === "adene") {
    return "0 20px 0";
  }

  return "20px";
};

const getWidth = (sTheme: string) => {
  if (sTheme === "nomad") {
    return "100%";
  }

  if (sTheme === "adene") {
    return "auto";
  }

  return "300px";
};

export default styled.div<{ stheme: string }>`
  background: ${(props) =>
    props.stheme !== "adene"
      ? props.theme.colors.white
      : "#3a3d4b 0% 0% no-repeat padding-box;"};
  border-radius: 5px;
  margin: 0 auto 0;
  padding: ${({ stheme }) => getPadding(stheme)};
  width: ${({ stheme }) => getWidth(stheme)};
`;
