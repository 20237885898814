import { useState } from "react";
import { verify as verifyToken } from "jsonwebtoken";
import axios from "axios";

import {
  getStoredToken,
  resetAuthState,
  saveAuthState,
  sendToken,
} from "../../../utils";
import AuthException from "../../../exceptions/AuthException";
import type AuthResponse from "../../../interfaces/authResponse";
import type Credentials from "../../../interfaces/credentials";
import ErrorInsert from "./ErrorInsert";
import Card from "./Card";
import LoginForm from "./LoginForm";
import Logo from "./Logo";
import View from "../../View";
import WelcomeText from "./WelcomeText";
import config from "../../../config";

import logoADS from "../../../ui/assets/img/logo-ADS.png";
import logoADENE from "../../../ui/assets/img/logo-ADENE.png";
import logoSANTELYS from "../../../ui/assets/img/logo-SANTELYS.png";
import logoAGIR from "../../../ui/assets/img/logo-AGIR.png";
import logoAIRPARTENAIRESANTE from "../../../ui/assets/img/logo-AIRPARTENAIRESANTE.png";

const getLogoClient = (client: string): any => {
  if (client.toUpperCase() === "ADENE") {
    return logoADENE;
  }

  if (client.toUpperCase() === "SANTELYS") {
    return logoSANTELYS;
  }

  if (client.toUpperCase() === "AGIR") {
    return logoAGIR;
  }

  if (client.toUpperCase() === "AIRPARTENAIRESANTE") {
    return logoAIRPARTENAIRESANTE;
  }

  return logoADS;
};

const loginUrl = `${config.serverURL}/auth/login`;

export default function LoginView() {
  const [errorMessage, setErrorMessage] = useState("");
  const queryString = window.location.search;
  const urlParams: any = new URLSearchParams(queryString);

  const clientUrl = urlParams.get("src");
  const theme = urlParams.get("theme") || "";
  const storedToken = getStoredToken();

  const logoSrc = getLogoClient(theme);

  async function authenticate({ password, username }: Credentials) {
    try {
      const { data } = await axios(loginUrl, {
        method: "POST",
        data: { username, password },
        withCredentials: true,
        // headers: {
        //   'Access-Control-Allow-Origin': '*',
        //   'Content-Type': 'application/json',
        // }
      });
      return data as AuthResponse;
    } catch (error: any) {
      console.error(error);
      throw new AuthException(error.response.status);
    }
  }

  async function handleSubmit(credentials: Credentials) {
    setErrorMessage("");

    if (!credentials.username || !credentials.password) {
      setErrorMessage("Veuillez saisir vos identifiants");
      return;
    }

    try {
      const authResponse = await authenticate(credentials);
      saveAuthState(authResponse);
      sendToken(authResponse.token, clientUrl);
    } catch (error: any) {
      setErrorMessage(error.message);
      throw error;
    }
  }

  if (!!storedToken) {
    try {
      verifyToken(storedToken, `${config.jwtPUBLIC}`);
      sendToken(storedToken, clientUrl);
      return null;
    } catch (error) {
      // TODO refresh token
      resetAuthState();
    }
  }

  if (theme === "adene" || theme === "nomad") {
    return (
      <View title="Connexion">
        <Card stheme={theme}>
          <LoginForm stheme={theme} onSubmit={handleSubmit} />
          {errorMessage && (
            <ErrorInsert stheme={theme}>{errorMessage}</ErrorInsert>
          )}
        </Card>
      </View>
    );
  }

  return (
    <View title="Connexion">
      <Card stheme={theme}>
        <Logo src={logoSrc} alt="" />
        <WelcomeText>
          Pour commencer, veuillez vous connecter avec vos identifiants
        </WelcomeText>
        <LoginForm stheme={theme} onSubmit={handleSubmit} />
        {errorMessage && (
          <ErrorInsert stheme={theme}>{errorMessage}</ErrorInsert>
        )}
      </Card>
    </View>
  );
}
