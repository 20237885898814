import { useState } from "react";
import styled from "styled-components";

import type Credentials from "../../../interfaces/credentials";
import Input from "./Input";
import SubmitButton from "./SubmitButton";

interface LoginFormProps {
  onSubmit: (credentials: Credentials) => Promise<void>;
  stheme?: string;
}

const Form = styled.form`
  div.default {
    margin: 15px 0;
  }
`;

const SubmitBtnAdene = styled.button`
  position: relative;
  display: inline-flex;
  padding: 1.8rem 7.4rem 1.6rem;
  background-color: rgba(147, 198, 83, 1);
  border-radius: 6.3rem;
  font-size: 1.8rem;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: all 0.1s linear;
  border: 0;
  margin: 0 auto;
  margin-top: 40px;
`;

const InputAdene = styled.input`
  background: #50555f 0% 0% no-repeat padding-box;
  border-radius: 13px;
  height: 70px;
  width: 420px;
  border: unset;
  outline-style: none;
  text-align: left;

  ::placeholder {
    font: normal normal medium 14px/20px Hero New;
    color: #ffffff;
    height: 20px;
  }
  font: normal normal medium 14px/20px Hero New;
  color: #ffffff;
  padding: 30px 25px;
`;

const FieldNomad = styled.div`
  margin: 0 0 2rem;

  &:last-child {
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 0 0 2.6rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin: 0 0 3.6rem;
  }
`;

const InputNomad = styled.input`
  display: block;
  padding: 2rem;
  width: 100%;
  font-size: 1em;
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 1rem;

  &::-webkit-input-placeholder {
    font-size: 1em;
  }

  &::focus {
    border: 1px solid #ededed;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 0 auto;
    padding: 2.5rem;
    max-width: 42rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 3rem;
  }
`;

const SubmitBtnNomad = styled.button`
  display: inline-block;
  padding: 0 5rem;
  height: 5rem;
  line-height: 5rem;
  color: #7163ba;
  text-decoration: none;
  font-size: 1.2em;
  background-color: #ffffff;
  border: 2px solid #7163ba;
  border-radius: 3.2rem;
  transition: all ease-in-out 0.25s;

  &:hover {
    color: #ffffff;
    background: #7163ba;
  }

  /* @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0 7.5rem;
    height: 6.4rem;
    font-size: 1.4em;
    line-height: 6.4rem;
  } */
`;

const initialCredentials: Credentials = { password: "", username: "" };

export default function LoginForm({ onSubmit, stheme }: LoginFormProps) {
  const [credentials, setCredentials] = useState(initialCredentials);
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCredentials((c) => ({ ...c, [e.target.id]: e.target.value }));
  }

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    try {
      setIsLoading(true);
      await onSubmit(credentials);
    } catch {
      setIsLoading(false);
    }
  }

  if (stheme === "nomad") {
    return (
      <Form style={{ textAlign: "center" }} onSubmit={handleSubmit}>
        <FieldNomad>
          <InputNomad
            autoFocus
            id="username"
            onChange={handleChange}
            placeholder="Identifiant"
            required
            value={credentials.username}
          />
        </FieldNomad>
        <FieldNomad>
          <InputNomad
            id="password"
            onChange={handleChange}
            placeholder="Mot de passe"
            required
            type="password"
            value={credentials.password}
          />
        </FieldNomad>

        <SubmitBtnNomad type="submit">Connexion</SubmitBtnNomad>
      </Form>
    );
  }

  if (stheme === "adene") {
    return (
      <Form style={{ textAlign: "center" }} onSubmit={handleSubmit}>
        <div className="default">
          <div style={{ position: "relative" }}>
            <InputAdene
              autoFocus
              id="username"
              onChange={handleChange}
              placeholder="Identifiant"
              required
              value={credentials.username}
            />
          </div>
        </div>
        <div className="default">
          <div style={{ position: "relative" }}>
            <InputAdene
              id="password"
              onChange={handleChange}
              placeholder="Mot de passe"
              required
              type="password"
              value={credentials.password}
            />
          </div>
        </div>

        <SubmitBtnAdene type="submit">Connexion</SubmitBtnAdene>
      </Form>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="default">
        <label htmlFor="username">Identifiant :</label>
        <Input
          autoFocus
          id="username"
          onChange={handleChange}
          placeholder="Identifiant"
          required
          value={credentials.username}
        />
      </div>

      <div className="default">
        <label htmlFor="password">Mot de passe :</label>
        <Input
          id="password"
          onChange={handleChange}
          placeholder="Mot de passe"
          required
          type="password"
          value={credentials.password}
        />
      </div>

      <SubmitButton loading={isLoading} type="submit">
        Connexion
      </SubmitButton>
    </Form>
  );
}
